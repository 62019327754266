import React from 'react'
import { RenderProp } from '../app/types'

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined)

  function useCtx() {
    const c = React.useContext(ctx)

    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value')
    }

    return c
  }
  return [useCtx, ctx.Provider] as const // 'as const' makes TypeScript infer a tuple
}

/**
 * Helper to handle rendering a render prop function or to return its node
 * @param { function } render - Render prop or ReactNode
 * @param { object? } options
 * @param { React.ElementType<any>? } options.container
 * @param { object? } options.props
 */
export function handleRenderProp<T = any>(
  render: RenderProp | React.ReactNode,
  options?: { container?: React.ElementType<any>; props?: T },
) {
  if (typeof render === 'function') {
    if (options && typeof options === 'object') {
      const Container = options.container
      const props = options.props
      return Container ? <Container>{render(props)}</Container> : render(props)
    }
    return render()
  }
  return render
}
