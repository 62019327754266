import React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

function CardTitle({
  children,
  ...rest
}: React.PropsWithChildren<TypographyProps>) {
  return (
    <Typography align="center" variant="h6" gutterBottom {...rest}>
      {children}
    </Typography>
  )
}

export default CardTitle
