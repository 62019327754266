import React from 'react'
import Divider from '@mui/material/Divider'
import type { CardProps } from '@mui/material/Card'
import type { TypographyProps } from '@mui/material/Typography'
import type { RenderProp } from 'app/types'
import { handleRenderProp } from 'utils/reactHelpers'
import CardRoot from '../Card'
import CardTitle from '../CardTitle'
import CardMedia from '../CardMedia'
import CardDescription from '../CardDescription'
import CardCaption from '../CardCaption'
import CardFooter from '../CardFooter'

export interface DefaultCardProps {
  CardProps?: CardProps
  CardDescriptionProps?: TypographyProps
  title?: RenderProp | React.ReactNode
  description?: RenderProp | React.ReactNode
  media?: RenderProp | string
  caption?: RenderProp | React.ReactNode
  footer?:
    | RenderProp<{
        Left: React.ElementType<any>
        Right: React.ElementType<any>
      }>
    | React.ReactNode
  url?: string
}

function DefaultCard({
  CardProps,
  CardDescriptionProps,
  title,
  description,
  media,
  caption,
  footer,
}: React.PropsWithChildren<DefaultCardProps>) {
  return (
    <CardRoot media={media} {...CardProps}>
      <div style={{ padding: 12 }}>
        {media && handleRenderProp(<CardMedia src={media as string} />)}
        {title && handleRenderProp(<CardTitle>{title}</CardTitle>)}
        {description &&
          handleRenderProp(
            <CardDescription {...CardDescriptionProps}>
              {description}
            </CardDescription>,
          )}
        {caption || footer ? <Divider style={{ margin: '10px auto' }} /> : null}
        {caption && handleRenderProp(<CardCaption>{caption}</CardCaption>)}
        {footer &&
          handleRenderProp(footer, {
            container: CardFooter,
            props: { Left: CardFooter.Left, Right: CardFooter.Right },
          })}
      </div>
    </CardRoot>
  )
}

export default DefaultCard
