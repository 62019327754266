import React from 'react'
import { css } from '@emotion/react'

export interface CardMediaProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  root?: JSX.IntrinsicElements['div']
}

function CardMedia({
  className,
  children,
  root,
  ...rest
}: React.PropsWithChildren<CardMediaProps>) {
  return (
    <div
      {...root}
      className={className}
      css={css`
        margin: 10px auto;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
      `}
    >
      <img
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `}
        {...rest}
      />
    </div>
  )
}

export default CardMedia
