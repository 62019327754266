import React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

function CardCaption({ children, ...rest }: TypographyProps) {
  return (
    <Typography
      {...rest}
      variant="caption"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        ...rest.style,
      }}
    >
      {children}
    </Typography>
  )
}

export default CardCaption
