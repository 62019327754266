import React from 'react'
import { css } from '@emotion/react'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { createCtx } from 'utils/reactHelpers'

export type CardFooterContext = [
  ('left' | 'right')[],
  React.Dispatch<React.SetStateAction<('left' | 'right')[]>>,
]

const [useCardFooterCtx, CardFooterProvider] = createCtx<CardFooterContext>()

function CardFooter({
  children,
  ...rest
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const [state, setState] = React.useState<CardFooterContext['0']>([])

  const leftExists = state.includes('left')
  const rightExists = state.includes('right')

  return (
    <CardFooterProvider value={[state, setState]}>
      <div
        css={css`
          display: flex;
          align-items: center;
          ${leftExists && rightExists ? `justify-content: space-between;` : ''}
          ${leftExists && !rightExists ? `justify-content: flex-start;` : ''}
          ${!leftExists && rightExists ? `justify-content: flex-end;` : ''}
        `}
        {...rest}
      >
        {children}
      </div>
    </CardFooterProvider>
  )
}

CardFooter.Left = function ({ children, ...rest }: TypographyProps) {
  const [state, setState] = useCardFooterCtx()

  React.useEffect(() => {
    !state.includes('left') && setState((prev) => ['left', ...prev])
  }, [state])

  return <Typography {...rest}>{children}</Typography>
}

CardFooter.Right = function ({ children, ...rest }: TypographyProps) {
  const [state, setState] = useCardFooterCtx()

  React.useEffect(() => {
    !state.includes('right') && setState((prev) => ['right', ...prev])
  }, [state])

  return <Typography {...rest}>{children}</Typography>
}

export default CardFooter
