import React from 'react'
import { css, ClassNames } from '@emotion/react'
import { graphql } from 'gatsby'
import useTheme from '@mui/material/styles/useTheme'
import Typography from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import LazyLoad from 'react-lazyload'
import DefaultCard from 'components/Card/DefaultCard'
import BlogPostCard, {
  Footer as BlogPostCardFooter,
} from 'components/Card/BlogPostCard'
import Button from 'components/common/Button'
import Page from 'components/Page'
import Link from 'components/common/Link'
import { DefaultMeta } from 'components/SEO'

interface Post {
  excerpt: string
  wordCount: {
    words: number
  }
  fields: {
    slug: string
    date: string
  }
  frontmatter: {
    title: string
    date: string
    thumbnail: string
    description: string
    author: string
    category: string
    tags: string[]
    lastUpdated: string
  }
}

interface HomepageProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: Post
      }>
    }
  }
}

function Homepage({ data }: HomepageProps) {
  const theme = useTheme()
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <DefaultMeta />
      <Page>
        <div style={{ height: 25 }} />
        <Hidden mdUp>
          {posts.map(({ node }, index) => (
            <LazyLoad key={node.fields.slug} height={250} once>
              <ClassNames>
                {({ css, cx }) => (
                  <DefaultCard
                    CardProps={{
                      className: cx(css`
                        ${theme.breakpoints.down('xs')} {
                          max-height: 400px;
                        }
                      `),
                    }}
                    CardDescriptionProps={{
                      className: cx(css`
                        overflow: hidden;
                        height: 90px;
                      `),
                    }}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                    url={node.fields.slug}
                    media={node.frontmatter.thumbnail}
                    footer={
                      <BlogPostCardFooter.Center>
                        <Typography
                          variant="overline"
                          style={{ display: 'block', width: '100%' }}
                          align="center"
                        >
                          {node.frontmatter.category}
                        </Typography>
                      </BlogPostCardFooter.Center>
                    }
                  />
                )}
              </ClassNames>
            </LazyLoad>
          ))}
        </Hidden>
        <Hidden smDown>
          {posts.map(({ node }, index) => (
            <LazyLoad key={node.fields.slug} height={250} once>
              <BlogPostCard
                title={node.frontmatter.title}
                description={node.frontmatter.description}
                url={node.fields.slug}
                media={node.frontmatter.thumbnail}
                showReadMore={false}
                footer={
                  <BlogPostCardFooter>
                    <BlogPostCardFooter.Left>
                      <Typography variant="overline">
                        {node.frontmatter.category}
                      </Typography>
                    </BlogPostCardFooter.Left>
                  </BlogPostCardFooter>
                }
                transparent
              />
              {index !== posts.length - 2 ? (
                <div style={{ height: 25 }} />
              ) : null}
            </LazyLoad>
          ))}
        </Hidden>
        <div style={{ height: 25 }} />
        <div
          css={css`
            ${theme.breakpoints.down('xs')} {
              text-align: center;
            }
          `}
        >
          <Link to="/posts">
            <Button size="large" variant="outlined">
              Read More
            </Button>
          </Link>
        </div>
      </Page>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { pageType: { eq: "post" } } }
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          wordCount {
            words
          }
          fields {
            slug
            date(formatString: "MMMM Do, YYYY")
          }
          frontmatter {
            title
            date
            author
            thumbnail
            description
            tags
            category
          }
        }
      }
    }
  }
`

export default Homepage
